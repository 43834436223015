// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
@mixin shadow-z-1() {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}

@mixin shadow-z-1-hover() {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

@mixin shadow-z-2() {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}

@mixin shadow-z-3() {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@mixin shadow-z-4() {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

@mixin shadow-z-5() {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

/* Shadows (from mdl http://www.getmdl.io/) */

// Focus shadow mixin.
@mixin big-shadow() {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
}

@mixin shadow-big() {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, $shadow-penumbra-opacity * 3),
    0 4px 25px 0px rgba(0, 0, 0, $shadow-ambient-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $shadow-umbra-opacity);
}

@mixin shadow-big-navbar() {
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, $shadow-penumbra-opacity * 3),
    0 3px 20px 0px rgba(0, 0, 0, $shadow-ambient-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $shadow-umbra-opacity);
}

@mixin shadow-big-color($color) {
  box-shadow: 0 12px 20px -10px rgba($color, $shadow-penumbra-opacity * 2),
    0 4px 20px 0px rgba(0, 0, 0, $shadow-ambient-opacity),
    0 7px 8px -5px rgba($color, $shadow-umbra-opacity);
}

@mixin sidebar-shadow() {
  box-shadow: 2px 10px 30px 0px rgba(0, 0, 0, $shadow-penumbra-opacity * 3),
    0 4px 25px 0px rgba(0, 0, 0, $shadow-ambient-opacity),
    0 8px 10px -5px rgba(0, 0, 0, $shadow-umbra-opacity);
}

@mixin card-shadow() {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
  // box-shadow: 0 6px 0px 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06);
}
