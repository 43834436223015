@import url("https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;600;700;800;900&display=swap");
$muli: "Muli", sans-serif;
body {
  font-family: $muli !important;
  & > p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $muli !important;
  }
}
.logo-img {
  > img {
    width: 50px;
    left: -10px;
    position: relative;
  }
}
.logo-login {
  width: 220px;
}
a {
  &:hover {
    text-decoration: none !important;
  }
}
svg {
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    border: none !important;
  }
}
.pd-top-32 {
  padding-top: 32px;
}
.pd-top-8 {
  padding-top: 8px;
}
.pd-top-16 {
  padding-top: 16px;
}
.pd-top-20 {
  padding-top: 20px;
}
.mg-top-40 {
  margin-top: 40px;
}
.mg-bt-2 {
  margin-bottom: 2px;
}
.mg-bt-32 {
  margin-bottom: 32px !important;
}
.mg-bt-24 {
  margin-bottom: 24px !important;
}
.pd-bottom-32 {
  padding-bottom: 32px;
}
.no-padding {
  padding: 0px !important;
}
.no-margin {
  margin: 0px !important;
}
.border-zero {
  border: 0px;
}
.cursor {
  cursor: pointer;
}

//Titulo das Páginas
.title-page {
  padding: 0px 0 32px 0;
  margin-bottom: 0;
  font-size: 29px;
  color: #535b6a !important;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.5px;
  margin-top: 40px;
  font-family: "Poppins" !important;
}
.menu-collapsed {
  padding: 0 32px;
}
.dropdown-menu-right {
  .dropdown-item:hover,
  .dropdown-item:focus {
    outline: none !important;
    background: #eaeaea !important;
    color: #16181b !important;
  }
}

.body-light {
  //navbar

  nav {
    padding: 5px 0;
  }

  .app-sidebar .logo-display-none {
    display: none;
  }
  .collapsed.app-sidebar .logo-display-none {
    display: initial;
  }
  .collapsed.app-sidebar .logo-sc {
    visibility: hidden;
  }
  .logo-studentcare {
    font-size: 24px;
    letter-spacing: 0.7px;
    font-family: "Muli", sans-serif;
    font-weight: 600;
  }
  span.nav-toggle {
    top: 28px;
  }
  .logo-universidade {
    margin-bottom: 0;
    margin-top: 0px;
    & > img {
      width: 300;
      height: 80px;
      object-fit: cover;
    }
  }
  @media (max-width: 991px) {
    .logo-universidade {
      position: absolute;
      left: 70px;
      margin-top: 12px;
    }
  }
  @media (max-width: 777px) {
    nav {
      padding-top: 16px !important;
    }
  }

  //Botões do Card
  & .btns-table {
    float: right;
  }
  & .btn-export-excel,
  & .btn-pesquisar-alunos {
    padding: 9px 22px;
    min-width: 151px;
    min-height: 36px;
    border-radius: 5px;
  }
  & .btn-export-excel {
    border: 1px solid #00c25b;
    background-color: #f6fcfa;
    color: #00c25b;
    margin-right: 24px;

    &:hover,
    &:focus,
    &:active {
      transition: all 150ms linear;
      border: 1px solid #00c25b;
      outline: none;
      background-color: #00c25b;
      color: #fff;
      box-shadow: none;
    }
  }
  & .btn-pesquisar-alunos {
    border: 1px solid #2f80ed;
    background-color: #2f80ed;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      transition: all 150ms linear;
      outline: none;
      box-shadow: none;
    }
  }
  .dropdown-filtros {
    position: absolute;
    //right: 30px;
    background: #fff;
    list-style: none;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    z-index: 999999;

    & .pesquisar-filtros {
      color: #717070;
      letter-spacing: 0.5px;
      font-weight: 700;
      text-transform: uppercase;
      z-index: 99;
    }

    & .input-pesquisar-filtros {
      margin-bottom: 16px;
    }

    & .radio-pd {
      margin-right: 8px;
    }

    & .label-dp-filtros {
      letter-spacing: 0;
      text-transform: none;
      font-size: 16px;
      font-weight: normal;
      color: #757575;
      margin-bottom: 0px;
    }

    & .cancelar-filtro {
      margin-right: 16px;
    }

    & .aplicar-filtro {
      background-color: #007bff;
      color: #fff;
      &:hover,
      &:focus {
        background: #0068d6 !important;
        border: 1px solid #0068d6 !important;
      }
    }

    & .cancelar-filtro,
    & .aplicar-filtro {
      margin-bottom: 0px;
      margin-top: 20px;
      padding: 6px 13px;
    }
  }
  & .tags-filtros {
    position: absolute;
    left: 0;
    list-style: none;
    padding: 0 32px 0 32px;
    color: #535a6a;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
  }

  & .tag-limpar {
    padding: 9px 22px;
    min-width: 151px;
    min-height: 36px;
    border-radius: 5px;

    // text-transform: uppercase;
    // margin-bottom: 0;
    // font-size: 14px;
    // margin-left: 24px;
    background: transparent;
    color: #6c757d;
    border: 1px solid #6c757d;
    font-weight: 500;
    // padding: 4px 10px;
    // letter-spacing: 0.5px;
  }

  //Card
  & .card-table,
  & .card-form,
  & .card-ticket {
    margin: 0;
    border-radius: 8px !important;

    & > .card-header {
      padding: 32px 32px 0px 32px !important;

      & > .card-text {
        font-size: 18px;
        font-weight: 500;
        float: left;
      }
    }

    // & .card-body {
    //   padding: 0 !important;
    // }

    & .card-title,
    .card-title-pesquisas,
    .card-title-contextos {
      position: relative;
      float: left;
      padding-bottom: 32px;
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
    }

    & .card-title-pesquisas {
      margin-top: 40px !important;
      padding-bottom: 16px !important;
    }

    & .card-title-contextos {
      margin-top: 0px !important;
      padding-bottom: 16px !important;
      font-size: 28px !important;
    }

    & .div-questoes {
      padding-bottom: 48px;

      & > .btn-questoes:nth-child(1) {
        padding: 0;
      }
      & > .btn-questoes {
        border: 0;
        padding: 0 0 0 32px;

        &:focus,
        &:active,
        &:hover {
          outline: none !important;
        }

        & > p {
          font-weight: normal;
          font-size: 16px;
          margin: 10px 0 0 0;
        }
      }
    }
  }

  & .card-ticket {
    // padding: 24px 24px 24px 34px !important;
    padding: 24px !important;
    margin-bottom: 32px;
    min-height: 236px;

    & > p,
    & ul > li {
      font-size: 16px;
      font-weight: bold;

      > span.id-ticket-span {
        font-size: 20px !important;
        // font-weight: 600;
      }
      > span {
        font-weight: normal;
      }
    }

    & > ul {
      list-style-type: none;
      margin-top: 16px;
      margin-bottom: 0px;
      padding: 0;

      & > li {
        line-height: 24px;
        border-right: 1px solid #b7b7b7;
        display: table-cell;
        padding-right: 24px;
        padding-left: 24px;

        &:last-child {
          border-right: none;
        }
        &:first-child {
          padding-left: 0px;
        }

        // & > span{
        //     line-height: 24px;
        // }
      }
    }

    & > figure {
      display: block;
      margin: 0 auto;

      & > img {
        width: 75px;
        height: 75px;
        object-fit: cover;
        border-radius: 100px;
      }
    }

    & > .info-aluno {
      & > p {
        text-align: center;
        line-height: normal;
      }

      & > .nome {
        margin-bottom: 0px;
        margin-top: 8px;
        font-size: 22px;
        font-weight: bold;
      }

      & > .curso,
      & > .campus,
      & > .risco {
        font-size: 15px;
      }
      & > .curso {
        font-weight: 600;
      }

      & > .curso,
      & > .campus,
      & > .risco {
        margin-top: 2.4px;
        margin-bottom: 0px;
      }

      & > .risco {
        & > span {
          font-weight: 800;
        }
      }
    }

    @media (max-width: 627px) {
      & > ul {
        margin-top: 0;
        & > li {
          display: table;
          padding: 0;
          border-right: 0;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  & .card-form {
    .card-body {
      padding: 32px !important;
      display: flow-root;

      & > input[type="tex-area"] {
        height: 50px;
      }

      & .form-control {
        margin-bottom: 0px;
      }

      .text-input-radio {
        & > label {
          color: #525252 !important;
        }
      }

      & > fieldset {
        label {
          font-size: 18px;
          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
        input {
          &:hover,
          &:focus {
            cursor: pointer;
          }
        }

        input[type="radio"] {
          width: 40px;
        }
      }
    }
  }

  //Tabela
  & .tabela-mod {
    td {
      // text-align: center;
    }
  }
  & .table {
    & td {
      border-top: 0 !important;
      padding: 20px 32px !important;
      font-size: 16px !important;
      vertical-align: middle;
      float: none !important;
      // color: #000 !important;

      & > a.btn {
        float: right;
      }
    }
    & th {
      vertical-align: middle;
      border: 0 !important;
      padding: 22px 32px !important;
      text-transform: uppercase !important;
      // color: #000 !important;
      font-weight: 600 !important;
      letter-spacing: 0.5px;
      font-size: 14px;

      //         & > div > svg{stroke: #000 !important;}
    }
    // & > tbody tr:nth-of-type(odd){
    //     background-color: #f9f9f9;
    // }
    .btn[class*="btn-"] {
      margin-bottom: 0px;
    }
  }

  & .table-action {
    & > thead {
      & > tr {
        & > th {
          border-right: 1px solid #fff !important;
          vertical-align: middle;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          border-right: 1px solid #e2e2e2 !important;
        }
      }
    }
    // & > tbody{
    //     & > tr{
    //         &:hover, &:focus{
    //             cursor: pointer;
    //              border: 1px solid #0298DB;
    //         }
    //     }
    // }
  }

  //Formulario
  & .informacao-card,
  & .informacao-card-form {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  & .informacao-card-form {
    font-weight: 600;
  }

  & .form-in-card {
    padding: 32px 32px;
  }

  & .select-label-position,
  .select-questao,
  .questao-text {
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    letter-spacing: 0px;
    text-transform: none;
  }

  & .select-questao {
    font-weight: normal !important;
    margin-bottom: 24px;
  }

  & .questao-text {
    margin-bottom: 16px;
  }

  & .form-control {
    padding: 13px 16px;
    border-radius: 5px;
    margin-bottom: 24px;
  }

  & .card-table {
    & .div-add-acao {
      margin-top: 0px;
      border-radius: 8px;
      padding: 32px 30px;

      & button {
        float: right;
        //padding: 20px 57px;
        padding: 15px 30px;
        font-size: 18px;
        line-height: normal;
      }
      & p {
        font-size: 20px;
        line-height: normal;
        padding-top: 18px;
        font-weight: 400;
      }
      & button,
      & p {
        margin-bottom: 0;
      }
    }
    & .btns-right {
      & > button {
        float: right;
      }
    }
    & .new-btn {
      font-size: 20px;
      padding: 14px 55px;
      margin-bottom: 0px;
      margin-top: 64px;
      min-width: 220px;
      line-height: normal;
    }

    .iniciar-atendimento {
      margin-top: 13px;
      float: right;
      border-radius: 5px;
      font-weight: normal;
      font-size: 20px;
      margin-bottom: 0;
      background: none;
      border: none;
      padding: 20px 32px;
      max-width: 256px;

      &:focus {
        box-shadow: none;
      }
    }

    & > div {
      & > .iniciar-atendimento {
        margin-top: 13px;
        float: right;
        border-radius: 5px;

        & > button {
          font-weight: normal;
          font-size: 20px;
          margin-bottom: 0;
          background: none;
          border: none;
          padding: 20px 32px;
          max-width: 256px;

          &:focus {
            box-shadow: none;
          }
        }
      }
      .dropdown-menu-atendimento {
        margin-top: 8px;
        padding: 0;
        left: -72px !important;

        & > button {
          padding: 16px 80px 16px 18px;
          border-bottom: 1px solid #e0e0e0;

          &:last-child {
            border: 0;
          }
        }
      }
    }

    & > .acoes-ticket {
      padding: 32px !important;
      border-radius: 8px;
      margin-bottom: 32px;

      & .acao {
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: bold;
      }

      & button.detalhes {
        padding: 3px 12px;
        border-radius: 3px;
      }
    }

    .action-plan-mod {
      padding: 24px;
      border-radius: 5px;
      margin-bottom: 32px;

      & > p:nth-child(1) {
        margin-bottom: 0px;
        color: #333;
        font-weight: 600;
        font-size: 18px;
      }
      & > p:nth-child(2) {
        margin-bottom: 0px;
        color: #000;
        font-weight: 300;
        font-size: 16px;
      }
    }
  }

  //Contextos
  & .card-contexto {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 32px;

    &:hover {
      cursor: pointer;
    }

    & > .favoritar {
      letter-spacing: 0.5px;
      font-size: 14px;
      line-height: normal;
      //padding: 3px 8px;
      position: absolute;
      right: 25px;
      top: 8px;
      border-radius: 3px;
    }

    & > figure {
      width: 100%;
      height: 137px;
      margin-bottom: 0px;

      & > img {
        width: 100%;
        height: 137px;
        object-fit: cover;
        border-radius: 7px 7px 0px 0px;
      }
    }

    & > .text-card-contexto {
      padding: 32px 24px;

      & > p {
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 0px;
      }
    }
  }
  & .buscar-contexto {
    width: 250px;
    & > label {
      font-size: 18px;
      letter-spacing: 0.5px;
      font-weight: 500;
      text-transform: none;
    }
    & > input {
      display: inline-block;
      width: 250px;
      margin-left: 16px;
    }
  }

  & .width-gp-ctx {
    margin-bottom: 32px;

    & .card-gp-ctx-academico {
      padding: 32px 16px;
      border-radius: 8px;
      height: 270px;

      &:hover {
        cursor: pointer;
        // -webkit-transition-duration: 2s;
        // transition-duration: 2s;
        // -webkit-transition-property: transform;
        // transition-property: transform;
        // -webkit-transform: scale(1.05);
        // transform: scale(1.05);

        & p:last-child {
          -webkit-line-clamp: initial;
        }
      }

      & > svg {
        width: 100%;
        height: 70px;
      }

      & > p {
        margin-top: 32px;
        margin-bottom: 0px;
        font-size: 18px;
        text-align: center;
        line-height: normal;
        font-weight: bold;
      }

      & > p:last-child {
        font-size: 14px;
        margin-bottom: 0px;
        line-height: normal;
        font-weight: normal;
        margin-top: 16px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        // &:hover{
        //     -webkit-line-clamp: initial;
        // }
      }
    }
  }

  //Resultado da Pesquisa
  & .total-enviados,
  & .total-abertos,
  & .total-respondidos,
  & .total-clicados {
    padding: 24px 24px;
    border-radius: 5px;
    display: grid;
    margin-bottom: 32px;
    min-height: 164px;
    align-items: center;
    & > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      & > li {
        & > p {
          line-height: normal;
          margin-bottom: 0;
        }
        & > p:nth-child(1) {
          font-size: 18px;
          // line-height: 36px;
          font-weight: 500;
          // float: left;
          // max-width: 140px;
        }

        & > p:nth-child(2) {
          // float: right;
          font-size: 56px;
          font-weight: bold;
          // top: -18px;
          // position: relative;
          // float: right;
        }
      }
    }
  }

  //Resultado da Timeline
  & .total-timeline-pq,
  & .total-timeline-tk,
  & .total-timeline-cm {
    //padding: 24px 24px;
    border-radius: 15px;
    display: grid;
    margin-bottom: 10px;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    & > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      align-items: center;

      & > li {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        & > p {
          text-align: center;
          line-height: normal;
          margin-bottom: 0;
        }
        & > p:nth-child(1) {
          font-size: 12px;
          // line-height: 36px;
          font-weight: 500;
          // float: left;
          // max-width: 140px;
        }

        & > p:nth-child(2) {
          // float: right;
          font-size: 16px;
          font-weight: bold;
          // top: -18px;
          // position: relative;
          // float: right;
        }
      }
    }
  }

  //Resultado da Timeline
  & .total-timeline-pa {
    //padding: 24px 24px;
    border-radius: 15px;
    display: grid;
    margin-bottom: 10px;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    & > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      align-items: center;

      & > li {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        & > p {
          text-align: center;
          line-height: normal;
          margin-bottom: 0;
        }
        & > p:nth-child(1) {
          font-size: 12px;
          // line-height: 36px;
          font-weight: 500;
          // float: left;
          // max-width: 140px;
        }

        & > p:nth-child(2) {
          // float: right;
          font-size: 30px;
          font-weight: bold;
          // top: -18px;
          // position: relative;
          // float: right;
        }
      }
    }
  }

  .input-chat {
    margin-bottom: 0px;
  }

  //ALUNOS
  .contact-search-box,
  .chat-fixed-search {
    padding: 24px 16px !important;
  }
  .chat-application .users-list-padding {
    padding-top: 87px;
  }
  .card-aluno-profile {
    border-radius: 0;
  }
  .card-aluno-profile {
    ul {
      li {
        a {
          letter-spacing: 0.5px;
          font-weight: 500;
        }
      }
    }
    .nome-aluno {
      font-weight: normal;
      font-size: 24px !important;
      letter-spacing: 0.5px;
      text-transform: capitalize !important;
    }
    .matricula-aluno {
      font-size: 15px !important;
      margin-bottom: 20px;
      letter-spacing: 1px;
      margin-top: 5px;
    }
  }
  @media (max-width: 767px) {
    .contact-app-list-mails {
      height: 400px;
    }
    .contact-app-mail-content {
      margin-top: 56px;
    }
  }
  .tab-pane {
    > div,
    > section {
      margin-top: 24px;
    }
  }
  //CHAT
  .chat-application {
    .chat-fixed-searc {
      width: 298px !important;
    }
    .aluno-chat {
      .list-group-item-heading {
        font-size: 18px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 190px;
      }
      .data {
        font-size: 13px !important;
      }
      .data,
      p {
        margin-bottom: 0px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 190px;
      }
    }
    .chat-app-form {
      padding: 33px 10px;
    }

    .adicionar-conversa {
      padding: 16px;
      // top: 513px;
      bottom: 0;
      position: sticky;
      width: 100%;
    }
  }
  .no-margin-input {
    input {
      margin: 0px !important;
    }
  }

  @media (max-width: 575px) {
    .no-margin-input {
      input {
        margin-bottom: 20px !important;
      }
    }
    .btn-enviar-chat {
      margin: 0 auto !important;
      display: block;
    }
    .chat-application .chat-app-form {
      padding: 20px 10px !important;
    }
  }

  //Email
  .email-app-list-mails {
    .scrollbar-container {
      overflow-y: hidden !important;
      height: calc(100% - 85px);
    }

    .email-app-list {
      overflow-x: hidden !important;
    }

    .email-search-box {
      padding: 24px 16px !important;

      input {
        margin-bottom: 0px !important;
      }
    }
  }
  .email-application {
    .email-app-mail-content-detail {
      padding: 0 !important;
    }
    .email-app-title {
      padding: 20px;

      > h3 {
        margin-bottom: 0;
      }
    }
    .media-email {
      padding: 0 20px;
    }
    .card-body {
      padding: 10px 20px !important;
    }
  }

  //Timeline
  // .timeline{
  //   padding: 24px 0 !important;
  // }
  .timeline-card {
    padding: 0px;
    margin: 0px;
  }
  .primeiro-item-timeline {
    margin-top: 0px !important;
  }
  .segundo-item-timeline {
    margin-top: 300px !important;
  }
  #timeline.timeline-center .timeline-item:nth-child(even) {
    margin-top: 400px;
  }
  #timeline.timeline-center .timeline-item:nth-child(odd) {
    margin-top: 400px;
  }
  .card-timeline-right {
    float: right;
  }
  .card-timeline {
    padding: 0px;
    & > .header,
    & > .body {
      padding: 24px;
    }
    & > .footer {
      display: flex;
      justify-content: center;
    }
    & > .header {
      & > .tempo {
        margin-bottom: 0;
        line-height: normal;
        font-size: 17px;
        font-weight: 600;
      }
    }
    & > div {
      & > .title {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 800;
      }
    }
    & > .body {
      & > .text-card-timeline {
        line-height: 24px;
        color: #333;
        margin: 0px;
      }
    }
  }
  #timeline.timeline-center .timeline-item:nth-child(even),
  #timeline.timeline-center .timeline-item:nth-child(odd) {
    padding: 0px;
  }

  #timeline.timeline-center .timeline-item:nth-child(even):before,
  #timeline.timeline-center .timeline-item:nth-child(even):after,
  #timeline.timeline-center .timeline-item:nth-child(odd):before,
  #timeline.timeline-center .timeline-item:nth-child(odd):after {
    display: none;
  }
  #timeline.timeline-center .timeline-item:nth-child(odd) > .timeline-badge {
    left: -35px;
  }
  .icon-evento-badge {
    width: 70px;
    border-radius: 100%;
    height: 70px;
    right: -35px !important;
  }
  .badge-evento-plano-estudo {
    background: #d5caf2;
    border: 1px solid #c4b0f9;

    & > svg {
      width: 32px;
      margin: 0px 18px;
      display: block;
      top: 3px;
      position: relative;
    }
  }
  .badge-evento-pesquisa {
    background: #bcebcd;
    border: 1px solid #8dd5ab;

    & > svg {
      width: 38px;
      margin: 0 15px;
      display: block;
      top: 5px;
      position: relative;
    }
  }
  .badge-evento-ticket {
    background: #ffccc9;
    border: 1px solid #ffb3ae;

    & > svg {
      width: 32px;
      margin: 0px 18px;
      display: block;
      top: 4px;
      position: relative;
    }
  }
  .badge-evento-comunicacao {
    background: #b3c9f3;
    border: 1px solid #8cb7f4;

    & > svg {
      width: 37px;
      margin: 0px 15px;
      display: block;
      top: 4px;
      position: relative;
    }
  }

  @media (max-width: 991px) {
    #timeline.timeline-center .timeline-item > .timeline-badge {
      left: 0% !important;
      position: relative;
      margin: 0 auto;
      display: block;
      margin-bottom: 16px;
    }
    .segundo-item-timeline {
      margin-top: 0px !important;
    }
    #timeline.timeline-center .timeline-item {
      padding: 64px 0 !important;
      display: inline-block;
    }
  }
  .text-pesquisar-aluno {
    margin: 20px 0 40px 0;
    h1 {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      line-height: normal;
      margin-top: 12px;
    }
    button {
      padding: 10px 22px;
      border: 1px solid #2f80ed;
      border-radius: 5px;
    }
    @media (max-width: 514px) {
      button {
        float: right;
        margin-top: 16px;
      }
    }
    @media (min-width: 515px) {
      h1 {
        float: left;
      }
      button {
        float: right;
      }
    }
  }
  @media (max-width: 990px) {
    .text-pesquisar-aluno {
      margin: 0px 0 40px 0;
    }
  }
  .tabs-mod {
    // margin-bottom: 48px;

    .nav-item {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;

      > a {
        padding: 8px 64px;
      }

      > a.active {
        font-weight: 600;
      }
    }
  }
  .horizontal-timeline-mod {
    margin-top: 48px !important;

    .timeline-eventline {
      height: 4px !important;
    }
    > div > div > ul > li {
      background-image: none !important;
    }
    ol {
      li {
        padding-bottom: 27px !important;
        span {
          bottom: -25px !important;
          width: 45px !important;
          height: 45px !important;
          left: 27px !important;
          background-color: #00ec94;
          border: 2px solid #00ec94;
        }
      }
    }
  }
  .timeline-evento:last-child {
    .linha-timeline {
      display: none;
    }
  }
  .timeline-evento {
    .card-evento,
    .card-descricao-evento {
      border-radius: 8px;
      cursor: pointer;
    }
    .card-evento {
      padding: 16px;

      > svg {
        width: 35px;
        margin: 0 auto;
        display: block;
      }
      > p {
        text-align: center;
        margin: 0px 0px 0px 0px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    @media (max-width: 767px) {
      .card-evento {
        margin-bottom: 16px;
      }
    }
    .card-descricao-evento {
      padding: 32px;

      > h1 {
        margin-bottom: 6px;
        font-size: 32px;
        text-transform: capitalize;
        font-weight: 400;
      }
      > p {
        color: #4e4e56;
        font-size: 17px;
        font-weight: 300;
        margin-bottom: 0px;
      }
    }
    .linha-timeline {
      ul {
        position: relative;
        display: block;
        margin: 0;
        padding: 10px 0;
        height: 130px;
        list-style: none;

        li {
          display: inline-block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          width: 4px;
          background-color: #e2e2e2;
          -webkit-transform: translate(-50%);
          transform: translate(-50%);
        }
      }
    }
  }

  //Perfil
  .perfil-aluno {
    .titulo {
      font-size: 21px;
      margin-bottom: 40px;
      padding-bottom: 16px;
      font-weight: 500;
      margin-top: 0px;
    }
    .section-card-info-aluno {
      // padding: 32px 0px;
      // border: 1px solid #e5e5e5;
      border-radius: 8px;
      margin-bottom: 40px;
    }
    .card-info-aluno {
      background-color: #fff !important;
      //padding-bottom: 0px;
      margin-bottom: 32px !important;
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 32px;
      //margin-top: 32px;
    }
    .card-inscricoes-ativas {
      > p:nth-child(1) {
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: 600;
      }
      > p:nth-child(2) {
        font-size: 16px;
        margin-bottom: 32px;
        font-weight: 500;
      }
    }

    .table-timeline {
      margin-top: 32px;
      margin-bottom: 0px;

      tbody {
        tr {
          td {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
  }
}

//Modal
.modal-header-ticket {
  border-radius: 0;
}
.modal-body-ticket {
  padding: 24px;

  & > .form-group {
    padding: 24px 0 0 0 !important;
    margin-bottom: 0px;

    & > textarea {
      &:focus {
        box-shadow: none;
        border-color: #ced4da !important;
      }
    }

    & > label {
      letter-spacing: 0.5px;
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  & > p.atendimento {
    font-size: 13px;
    text-align: center;
    margin-top: 12px;
  }
}
.modal-footer-ticket {
  padding: 16px 24px 24px 24px;
  border: 0 !important;
}

.ul-modal-ticket {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;

  & > li {
    padding: 14px 16px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: bold;
    &:last-child {
      border: 0;
    }
    & > p {
      float: right;
      font-weight: normal;
      margin-bottom: 0px;
    }
    @media (max-width: 600px) {
      & > p {
        float: initial;
      }
    }
  }
}
.btn-cancelar-modal-ticket,
.btn-continuar-modal-ticket {
  padding: 10px 24px;
  border-radius: 5px;
  min-width: 119px;
}

//Modal do Analytics
.modal-analytics {
  & > .modal-content {
    padding: 32px;
  }
  .title-modal-analytics {
    border-bottom: 0px;
    padding: 0;

    // & > .close {
    //   display: none;
    // }

    & > .modal-title {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0;

    & > p {
      margin-bottom: 32px;
    }
  }

  .table th,
  .modal-footer {
    border: 0;
  }

  .tabela {
    margin-top: 32px;
    height: 400px;
    overflow: auto;
    border-radius: 8px;
    // display: table-row-group;
  }

  .funcionalidade {
    padding: 39px 24px;
    border-radius: 5px;
    margin-bottom: 24px;
    min-height: 210px;

    &:hover {
      cursor: pointer;
    }

    & .svg-icon {
      & > svg {
        margin: 0 auto;
        display: block;
        // width: 80px;
        // height: 80px;
      }
    }

    & .nome-funcionalidade,
    & .texto-funcionalidade {
      text-align: center;
    }
    & .nome-funcionalidade {
      font-size: 18px;
      font-weight: bold;
      margin: 8px 0 5px 0;
      line-height: normal;
    }
    & .texto-funcionalidade {
      font-size: 14px;
      margin-bottom: 0px;
      // padding: 0 32px;
      line-height: normal;
    }
  }
}

//login
@media (max-height: 630px) {
  .login-layout {
    height: 130vh;
  }
}
.card-login {
  width: 550px;
  padding: 32px 60px;

  @media (max-width: 530px) {
    padding: 24px;
  }
  svg {
    // width: 260px;
  }
  h2 {
    font-size: 18px;
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 32px;
    line-height: normal;

    > span {
      font-weight: 600;
    }
  }
  .form-group:nth-child(1),
  .form-group:nth-child(2) {
    margin-bottom: 35px;
  }
  .label-login {
    margin-bottom: 6px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    text-align: left;
    color: #2d2d2d;
  }
  input {
    height: 40px !important;
  }
  .card-body {
    padding: 0;
  }
  button {
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .btn-entrar {
    max-width: 250px;
    border-radius: 100px;
    height: 57px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 500;
    margin: 48px auto 0 auto;
  }
}

//Tabela Alunos
.tabela-alunos {
  border: 1px solid #e5e5e5 !important;
  // height: 450px;

  table {
    margin-bottom: 0px;

    input {
      border: 1px solid #e5e5e5;
      border-radius: 3px;

      &:not([type="checkbox"]) {
        //width: 230px !important;
      }
    }

    > thead {
      color: #fff;
      background: #2f7fec;

      span {
        color: #fff;
      }
    }
    td,
    th {
      vertical-align: middle;
    }
    td {
      padding: 24px 10px;
      border-bottom: 1px solid #e5e5e5;
    }
    tbody {
      tr {
        background-color: #fff;
      }
      tr:nth-of-type(odd) {
        background-color: #f6f6f6;
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }
}
.run-title {
  font-size: 20px;
  font-weight: 700;
  color: #4e4e4e;
  margin-bottom: 10px;
}
.botoes-run {
  // float: right;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 40px auto 48px auto;
    display: block;
    text-align: center;
    // float: right;

    > li {
      display: inline-block;
    }
  }
  button {
    // float: right;

    &:first-child {
      margin-left: 24px;
    }
  }
}
.pagination-mod {
  margin: 30px auto !important;
  display: block !important;
  text-align: center;

  > .div-btns {
    > .span-paginas {
      margin: 0 16px;
    }
    > button {
      border: 0;
      // color: #4e9aff;
      font-weight: 600;
      background: transparent;
      border: 1px solid #0cc27e;
      border-radius: 100px;
      padding: 5px;
      fill: #0cc27e;

      &:hover {
        background: #0cc27e;
        fill: #fff;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .span-pagination {
    border-left: 1px solid #9e9e9e;
    padding: 0 0px 0px 18px;
    margin: 0 16px 0 16px;
  }
  @media (max-width: 768px) {
    .span-pagination {
      content: "";
      clear: both;
      display: block;
      margin-bottom: 16px;
      margin-top: 16px;
      border: 0;
    }
  }
}
.modal-footer {
  padding: 12px 0px 0px 0px;
}
.modal-content .modal-footer .btn + .btn {
  margin-left: 24px;
}

.enviar-modal {
  float: right;
  margin: 32px 0 0 0;
}

//ADD TICKET
.enviar-ticket {
  float: right;
  margin: 32px 0 0 0 !important ;
}
.rdw-editor-wrapper {
  > div {
    border-radius: 5px !important;
  }
  > .rdw-editor-toolbar {
    padding: 12px;

    .rdw-option-wrapper {
      background-color: transparent;
      border: none;
      margin: 0px;
      padding: 15px;

      &:hover {
        box-shadow: none;
        border-radius: 3px;
      }
    }
  }
  .rdw-editor-main {
    padding: 0 24px !important;

    // .public-DraftStyleDefault-ltr{
    //     display: none;
    // }
  }
}
.total-paginas-registros {
  //   text-align: center;

  p:first-child {
    margin-bottom: 8px;
    margin-top: 16px;
  }
  p {
    color: #444;
    font-weight: 500;

    span {
      font-weight: 600;
      color: #000;
    }
  }
}
.registro-por-pagina {
  margin: 0 auto;
  display: block;
  color: #6b6f82;
  float: right;

  > label {
    // margin: 24px auto 10px 0;
    // display: block;
    // text-align: center;
  }
  .basic-single {
    position: relative;
    width: 200px;
    float: right;
    z-index: 999999;
  }
}
@media (max-width: 580px) {
  .total-paginas-registros {
    p {
      text-align: center !important;
    }
  }
  .registro-por-pagina {
    float: none;
    > label {
      text-align: center;
      margin: 0 auto 8px auto;
      display: block;
    }
    > .basic-single {
      margin: 0 auto;
    }
  }
}
