.form-group {
  .checkbox label,
  .radio label {
    color: rgba(#000000, 0.87);
  }
}

// Buttons
.btn-group,
.input-group {
  span {
    &:first-child:not(:last-child) {
      .btn.dropdown-toggle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child:not(:first-child) {
      .btn.dropdown-toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:not(:last-child):not(:first-child) {
      .btn.dropdown-toggle {
        border-radius: 0;
      }
    }
  }
}

// List Group
.list-group-item {
  &:hover {
    z-index: 0;
  }
  &.active,
  &.active:focus,
  &.active:hover {
    background-color: theme-color("primary");
    border-color: theme-color("primary");
  }
}

// Labels
.label {
  padding-bottom: 0.1em !important;
  letter-spacing: 0.5px;
  &.label-default {
    background-color: #777 !important;
  }
}

// Media Object
.media-list {
  &.media-bordered {
    .media {
      border-top: 1px solid #e4e7ed;
      padding: 1rem 0;
      &:first-child {
        border-top: 0;
      }
    }
  }
}
.media {
  img,
  iframe {
    margin-right: 1rem !important;
  }
  .media-body + a {
    img {
      margin-right: 0 !important;
      margin-left: 1rem;
    }
  }
  .media-body.text-right + a {
    img {
      margin-left: 0;
    }
  }
  .media-body,
  .media-left,
  .media-right {
    padding-top: 0;
  }
  .round-media {
    border-radius: 50%;
  }
}

// Dropdown
.dropdown-menu {
  display: none;
  opacity: inherit;
  .active {
    a {
      background-color: theme-color("primary");
      &:focus,
      &:hover {
        background-color: theme-color("primary");
      }
    }
  }
  &.show {
    transform: none;
    opacity: 1;
  }
}
.form-control {
  &.open {
    ~ .dropdown-menu {
      transform: none;
      opacity: 1;
    }
  }
}

// Tabs/Navs/Pills
.tab-content {
  padding: 1rem;
}
.nav-tabs {
  .nav-link {
    height: 100%;
    &.active {
      background-color: transparent;
      border-bottom-color: white;
    }
  }
  .nav-item {
    &.show {
      .nav-link {
        background-color: transparent;
        border-bottom-color: white;
      }
    }
  }
}

// Accordion
ngb-accordion {
  [role="tabpanel"] {
    padding: 0 2.5rem !important;
  }
  .card {
    border-bottom: 2px solid #f5f7fa;
    margin: 0;
  }
}

// Sweet Alert
.swal2-modal {
  .swal2-confirm {
    background-color: theme-color("success") !important;
  }
  .swal2-cancel {
    background-color: theme-color("danger") !important;
  }
}

[dir="rtl"] {
  .media {
    img,
    iframe {
      margin-left: 1rem !important;
      margin-right: auto !important;
    }
    .media-body + a {
      img {
        margin-left: 0 !important;
        margin-right: 1rem;
      }
    }
    .media-body.text-right + a {
      img {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}
