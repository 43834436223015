//Search Navbar
#search-nav {
  .nav {
    border-bottom: 2px solid #e0e0e0;
  }
  .nav-link {
    &.active {
      padding-bottom: 6px;
      border-bottom: 2px solid theme-color("primary");
      margin-bottom: -2px;
    }
  }
}

#search-results {
  .media-search {
    padding-top: 5px;
  }

  p {
    font-size: 0.9rem;
  }

  .media-list {
    padding: 0;
    .media {
      padding: 1.25rem;
      margin-top: 0;
    }
  }
}
