.chat-list-contact {
    .header-chat {
        height: 56px;
        width: 100%;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;

        .suas-msgs {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #245fad;
            align-self: center;
            margin: 0px;
        }

        .header-chat-actions {
            display: flex;

            .form-control {
                margin-bottom: 0px !important;
                margin-right: 24px;
                flex-basis: content;
            }

            .btn-nova-conversa {
                width: 170px;
                height: 40px;
                border: 0px;
                background: #245fad;
                border-radius: 2px;
                padding: 8px 16px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #ffffff;
                position: relative;
                display: flex;
                align-items: center;

                i {
                    margin-right: 5px;
                    font-size: 20px;
                }
            }
        }
    }

    .chat-contacts {
        height: 700px;
        overflow-y: auto;
        .chat-contact {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 12px 24px;
            border-bottom: 1px solid #eeeeee;

            .disciplina-contact,
            .nome-contact,
            .last-msg-contact {
                font-family: "Montserrat";
                font-style: normal;
                font-size: 12px;
            }

            .contact-image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #c1c1c1;
                margin-right: 16px;
                align-self: center;
            }

            .nome-contact {
                font-weight: 700;
                line-height: 15px;
                text-transform: uppercase;
                color: #424242;
            }

            .disciplina-contact {
                font-weight: 400;
                line-height: 22px;
                color: #9e9e9e;
            }

            .last-msg-contact {
                font-weight: 400;
                line-height: 22px;
                color: #424242;
            }

            .chat-total-msg {
                display: flex;
                flex-direction: column;
                align-items: center;

                .time {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 22px;
                    color: #9e9e9e;
                    margin-bottom: 8px;
                }

                .total {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 8px;
                    gap: 10px;
                    width: 34px;
                    height: 33px;
                    background: #f57c00;
                    border-radius: 56px;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                }
            }
        }
    }
}

.chat-app-form {
    .chat-messages {
        width: 100%;
        height: 500px;
        overflow-y: scroll;
        padding: 10px;

        .time-chat {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: #9e9e9e;
        }

        .right-wrapper {
            display: block;
            max-width: 380px;
            display: block;
            margin-left: auto;

            .time-chat {
                text-align: end;
            }

            .right-message {
                background: #245fad;
                border-radius: 16px 16px 0px 16px;
                padding: 10px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #ffffff;
            }
        }

        .left-wrapper {
            .user-image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #c1c1c1;
                margin-right: 16px;
            }

            .left-message {
                background: #bbdefb;
                border-radius: 16px 16px 16px 0px;
                padding: 10px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                /* identical to box height, or 183% */

                /* $Cinza_800 */

                color: #424242;
                max-width: 380px;
                display: block;
                margin-right: auto;
            }
        }
    }

    .chat-textbox {
        padding: 10px;
    }
}

.chat-app-modal {
    .contact-recent {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
    }

    .contacts-select {
        margin-bottom: 16px;
    }

    .chat-contacts {
       

        .chat-contact {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding: 12px 0px;
            border-bottom: 1px solid #eeeeee;

            .select-contact {
                align-self: center;
                margin-left: auto;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #2196f3;
                cursor: pointer;
            }
        }
    }
    .disciplina-contact,
    .nome-contact {
        font-family: "Montserrat";
        font-style: normal;
        font-size: 12px;
    }

    .contact-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #c1c1c1;
        margin-right: 16px;
        align-self: center;
    }

    .chat-contact-info {
        align-self: center;
    }

    .nome-contact {
        font-weight: 700;
        line-height: 15px;
        text-transform: uppercase;
        color: #424242;
    }

    .disciplina-contact {
        font-weight: 400;
        line-height: 22px;
        color: #9e9e9e;
    }

    .last-msg-contact {
        font-weight: 400;
        line-height: 22px;
        color: #424242;
    }
}
